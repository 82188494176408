import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { DetailsList, IColumn, IDetailsListStyles, Label, Pivot, PivotItem, SelectionMode, Stack, Text } from '@fluentui/react';
import { getTournamentSummary } from '../../../ApiService';

import { useAccount, useMsal } from "@azure/msal-react";
import { useMediaQuery } from 'react-responsive';
import { TournamentSummary } from '../../../model/TournamentSummary';
import Loader from '../../../components/Loader';
import { GetPriceDisplayString } from '../../../Display';

interface IData {
    tournamentId: string;
}

const Summary : FC<IData> = ({tournamentId}) => {  
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

  const [tournamentSummary, setTournamentSummary] = useState<TournamentSummary>();

  const [errorCode, setErrorCode] = useState<number>();

  interface IFinancialRow {
      key: string;
      description: string;
      amount: string;
      isTotal: boolean;
      isFee: boolean;
  }

  interface IDisbursementRow extends IFinancialRow {
    periodStart: Date;
    periodEnd: Date;
    numAthletes: number;
  }

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    }, [errorCode]);
    
    const [rows, setRows] = useState<IFinancialRow[]>([]);
    const [disbursementRows, setDisbursementRows] = useState<IDisbursementRow[]>([]);

    useEffect(()=> {
        const fetchAsync = async () => {
            var summary = await getTournamentSummary(instance, account!,tournamentId);
            
            if(typeof summary === 'number') {
                setErrorCode(summary);
                return;
            }

            const summaryRows: IFinancialRow[] = [
                { key: 'gross', isTotal: false, isFee: false, description: 'Total Collected', amount: GetPriceDisplayString(summary.TotalCollected)  },
                { key: 'cardProcessorFees', isTotal: false, isFee: true, description: 'card processor fees', amount: `(${GetPriceDisplayString(summary.CardProcessorFees)})` },
                { key: 'npnlFees', isTotal: false, isFee: true, description: 'ninjapanel platform fees', amount: `(${GetPriceDisplayString(summary.NpnlFees)})` },
                { key: 'net', isTotal: true, isFee: false, description: 'Net Proceeds', amount: GetPriceDisplayString(summary.TotalCollected - summary.CardProcessorFees - summary.NpnlFees) }
            ];
            
            const disbursementRows: IDisbursementRow[] = [];

            if(summary.Disbursements !== undefined) {
                summary.Disbursements.forEach(d => {
                    disbursementRows.push({
                        key: d.PeriodStart.toString(),
                        description: d.Method,
                      amount: GetPriceDisplayString(d.Amount - d.AmountWitheld),
                        numAthletes: d.NumAthletes,
                        periodStart: d.PeriodStart,
                        periodEnd: d.PeriodEnd,
                        isTotal: false,
                        isFee: false
                    });
                });
            }
            
            setDisbursementRows(disbursementRows);
            setRows(summaryRows);
            setTournamentSummary(summary);
        }

        fetchAsync();        
    }, [tournamentId, account, instance]);
    
    const listStyles: Partial<IDetailsListStyles> = {
        root: {
          overflow: 'hidden',
        },
      };

    const summaryColumns: IColumn[] = [
        {
          key: 'column1',
          name: 'Description',
          fieldName: 'description',
          minWidth: 200,
          maxWidth: 300,
            isResizable: true,
          onRender: (item: IFinancialRow) => {
              return <Text style={{ paddingLeft: item.isFee ? 10 : 'initial', fontWeight: item.isTotal ? 'bold' : 'initial'}}>{item.description}</Text>;
          }
        },
        {
          key: 'column2',
          name: 'Amount',
          fieldName: 'amount',
          minWidth: 100,
          maxWidth: 150,
            isResizable: true,          
          data: 'string',
          isSorted: false,
            isSortedDescending: false,
            onRender: (item: IFinancialRow) => {
                return <Text style={{textAlign:'right', fontWeight: item.isTotal ? 'bold' : 'initial'}}>{item.amount}</Text>;
            }
        },
    ];
    
    const disbursementColumns: IColumn[] = [
        {
          key: 'column1',
          name: 'Method',
          fieldName: 'description',
          minWidth: 35,
          maxWidth: 50,
          isResizable: true,
        },
        {
          key: 'column2',
          name: 'Amount',
          fieldName: 'amount',
          minWidth: 50,
          maxWidth: 75,
          isResizable: true,
          data: 'string',
          isSorted: false,
          isSortedDescending: false,
      },
      {
        key: 'column3',
        name: 'Unique Registrants',
        fieldName: 'numAthletes',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        data: 'number',
        isSorted: false,
        isSortedDescending: false,
      },
        {
          key: 'column4',
          name: 'Period Start',
          fieldName: 'periodStart',
          minWidth: 50,
          maxWidth: 75,
          data: 'string',
          isResizable: true,
          onRender: (item: IDisbursementRow) => {
              return <Text>{item.periodStart.toLocaleDateString()}</Text>;
          }
        },
        {
          key: 'column5',
          name: 'Period End',
          fieldName: 'periodEnd',
          minWidth: 50,
          maxWidth: 75,
          data: 'string',
          isResizable: true,
          onRender: (item: IDisbursementRow) => {
              return <Text>{item.periodEnd.toLocaleDateString()}</Text>;
          }
        },
      ];
    
    return (
          <AuthenticatedTemplate>
            <div style={{ marginTop:40, minWidth: isMobile ? 100 : 400 }}>
                {tournamentSummary === undefined ?
                    <Loader Text='Just a moment...' /> :
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack style={{backgroundColor:'white', padding:20, width:500, marginLeft:'auto', marginRight:'auto'}}>
                            <Text variant='large' style={{ textAlign: 'center' }}>Financials</Text>
                            <br />
                            <DetailsList
                                items={rows}
                                columns={summaryColumns}
                                setKey="financials"
                                layoutMode={0 /* DetailsList.LayoutMode.justified */}
                                isHeaderVisible={false}
                                selectionMode={SelectionMode.none}
                                styles={listStyles}
                                />
                                                        
                            <br /><br />
                            <Text variant='large' style={{ textAlign: 'center' }}>Registrations</Text>
                            <br />
                            <Pivot>
                                <PivotItem headerText='Roles'>
                                {tournamentSummary?.Roles === undefined || tournamentSummary?.Roles.length === 0 ?
                                <Text variant='smallPlus'>None yet.</Text> :
                                    tournamentSummary?.Roles.map((r, idx) =>
                                    <Stack key={idx}>
                                        <Label>{r.Role}</Label>
                                        <Text variant='medium'>{r.NumMembers}</Text>
                                    </Stack>)}
                                </PivotItem>
                                <PivotItem headerText='Events'>
                                {tournamentSummary?.Events === undefined || tournamentSummary?.Events.length === 0 ?
                                <Text variant='smallPlus'>None yet.</Text> :
                                tournamentSummary?.Events.map((e, idx) =>
                                <Stack key={idx}>
                                    <Label>{e.EventName}</Label>
                                    <Text variant='medium'>{e.NumAthletes} athletes {e.DivisionsWithoutMatch !== null && e.DivisionsWithoutMatch?.length > 0 ? "({e.DivisionsWithoutMatch.length} currently unmatched)" : ""}</Text>
                                </Stack>)}
                                </PivotItem>
                                <PivotItem headerText='Catalog'>
                                {tournamentSummary?.Catalog === undefined || tournamentSummary?.Catalog.length === 0 ?
                                <Text variant='smallPlus'>None yet.</Text> :
                                tournamentSummary?.Catalog.map((e, idx) =>
                                <Stack key={idx}>
                                        <Label>{e.ItemName}</Label>
                                        <Text variant='small'>{e.Configuration}</Text>
                                        <Text variant='medium'>{e.NumPurchases} purchased</Text>
                                </Stack>)}
                                </PivotItem>
                            </Pivot>
                            
                            
                            <br />
                            
                            <br />
                            <Text variant='large' style={{ textAlign: 'center' }}>Disbursements</Text>
                            <br />
                                {tournamentSummary.Disbursements === undefined || tournamentSummary.Disbursements.length === 0 ?
                                <Text variant='smallPlus'>None yet.</Text> :                                
                                <DetailsList
                                    items={disbursementRows}
                                    columns={disbursementColumns}
                                    setKey="disbursements"
                                    layoutMode={0 /* DetailsList.LayoutMode.justified */}
                                    isHeaderVisible={true}
                                    selectionMode={SelectionMode.none}
                                    styles={listStyles}
                                    />
                            }
                        </Stack>                                                   
                    </Stack>
                }
            </div>
          </AuthenticatedTemplate>
    )
}

export default Summary;